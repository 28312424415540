import React from "react";
import "./ProductCard.css";

/* USAGE

<ProductCard
  name="Coffee"
  image={coffeeImage}
  price={2.0}
  soldOut={true}
/>

*/

const ProductCard = ({ image, name, description, price, soldOut }) => {
  return (
    <div className={`product-card ${soldOut ? "sold-out" : ""}`}>
      <div className="image-container">
        <img src={image} alt={name} className="product-image" />
        {soldOut && <div className="sold-out-overlay">SOLD OUT</div>}
      </div>
      <div className="product-info">
        <h3 className="product-name">{name}</h3>
        <h3 className="product-description">{description}</h3>
        <p className="product-price">£{price.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default ProductCard;
