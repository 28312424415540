import React from "react";
import "./ProductCardVariableProduct.css";

/* DATA

const candyflossVariants = [
        { name: "Strawberry", price: 2.5, soldOut: false },
        { name: "Vanilla", price: 2.5, soldOut: true },
        { name: "Chocolate", price: 2.5, soldOut: false },
    ];

*/



/* USAGE

<ProductCardVariableProduct
    name="Candyfloss"
    image={candyflossImage}
    variants={candyflossVariants}
/>

*/


const ProductCardVariableProduct = ({ image, name, description, variants, masterPrice }) => {
    return (
        <div className="product-card-variable">
            <div className="header">
                <img src={image} alt={name} className="product-image" />
                <h3 className="product-name">{name}</h3>
                <h3 className="product-description">{description}</h3>
                <p className="product-price">£{masterPrice.toFixed(2)}</p>
            </div>

            <div className="variants-list">
                {variants.map((variant, index) => (
                    <div
                        key={index}
                        className={`variant ${variant.soldOut ? "variant-sold-out" : ""}`}
                    >
                        <span className="variant-name">{variant.name}</span>
                        <span className="variant-description">{variant.discription}</span>
                    </div>
                ))}
                
            </div>
            
        </div>
    );
};

export default ProductCardVariableProduct;
