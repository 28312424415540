import React from "react";
import "./Category.css";
import ProductCard from "./ProductCard";
import ProductCardVariableProduct from "./ProductCardVariableProduct";

/* STANDARD PRODUCTS SETS NO VARIENTS
const hotDrinks = [
        { type: "normal", name: "Tea", price: 1.5, image: teaImage, soldOut: false },
        { type: "normal", name: "Coffee", price: 2.0, image: coffeeImage, soldOut: true },
    ];

*/

/* PRODUCT SET WITH MIXED TYPES
const sweetTreats = [
        {
            type: "variable",
            name: "Candyfloss",
            image: candyflossImage,
            variants: [
                { name: "Strawberry", price: 2.5, soldOut: false },
                { name: "Vanilla", price: 2.5, soldOut: true },
                { name: "Chocolate", price: 2.5, soldOut: false },
            ],
        },
        {
            type: "variable",
            name: "Candyfloss",
            image: candyflossImage,
            variants: [
                { name: "Strawberry", price: 2.5, soldOut: false },
                { name: "Vanilla", price: 2.5, soldOut: true },
                { name: "Chocolate", price: 2.5, soldOut: false },
            ],
        },
        { type: "normal", name: "Tea", price: 1.5, image: teaImage, soldOut: false },
    ];

*/






const Category = ({ title, description, products }) => {
  return (
    <div className="category">
      <h2 className="category-title">{title}</h2>
      
      <div className="product-list">
        {products.map((product, index) => {
          // Render based on product type
          if (product.type === "variable") {
            return (
              <ProductCardVariableProduct
                key={index}
                name={product.name}
                description={product.description}
                image={product.image}
                masterPrice= {product.masterPrice}
                variants={product.variants}
              />
            );
          }
          // Default to normal ProductCard
          return (
            <ProductCard
              key={index}
              name={product.name}
              description={product.description}
              image={product.image}
              price={product.price}
              soldOut={product.soldOut}
            />
          );
        })}
      </div>
      <h2 className="category-description">{description}</h2>
    </div>
  );
};

export default Category;
