import React from "react";
import "./Display.css";
import "./Cerebri-Sans-Book.ttf";
import "./Cerebri-Sans-Bold.ttf";
import "./Cerebri-Sans-Regular.ttf";
import Logo from "./Standard Logo - Horizontal.svg";
import QRCode from "./AllergensLink.svg";
import NCASS_LOGO from "./NCASS-LOGO.jpg";
import FOOD_HYGINE_RATING from "./FHR.svg";
import ProductCardVariableProduct from "./components/ProductCardVariableProduct";

import Snowfall from 'react-snowfall';


import Category from "./components/Category";

import teaImage from "./images/tea.jpg";
import coffeeImage from "./images/coffee.jpg";
import hotChocolateImage from "./images/hot_chocolate.png";
import moninImage from './images/monin.png';
import cansImage from './images/cans.png';

import iceCreamImage from './images/iceCream.png';
import flakeImage from './images/flake.jpg';

import sugarDummyImage from './images/sugarDummy.png';
import largeTwistLollipopImage from './images/largeTwistLollipop.png';
import roundWheelLollipopImage from './images/roundWheelLollipop.png';
import candyflossImage from "./images/candyfloss.png";

import popcornImage from './images/popcorn.png';
import crispsImage from './images/crisps.png';

import miniDiscoBall from "./images/miniDiscoBall.jpg";
import flashingFoamStick from "./images/flashingFoamStick.jpg";
import smileyFaceBallImage from './images/smileyBall.png';

import bundleImage from './images/bundle.png';


const snowflake1 = document.createElement('img');
snowflake1.src = '/images/snowflake1.png';
const snowflake2 = document.createElement('img');
snowflake2.src = '/images/snowflake2.png';


const snowflakeImages = [snowflake1, snowflake2];

const Display = () => {

    const drinks = [
        {
            type: "variable",
            name: "Tea",
            description: "Yorkshire Tea, Let's have a proper brew!",
            image: teaImage,
            masterPrice: 1.6,
            variants: [
                { name: "Decaf also available", soldOut: false },
            ],
        },
        {
            type: "variable",
            name: "Coffee",
            description: "NESCAFÉ® Azera Americano",
            image: coffeeImage,
            masterPrice: 2.6,
            variants: [
                { name: "Decaf also available", soldOut: false },
            ],
        },
        { type: "normal", name: "Hot Chocolate", description: "Cadburys Hot Chocolate with real dairy squirty cream and marshmellows", price: 2.8, image: hotChocolateImage, soldOut: false },
        {
            type: "variable",
            name: "Falvouring Syrups",
            image: moninImage,
            description: "These can be added to your coffee or hot choocolate",
            masterPrice: 0.5,
            variants: [
                { name: "Caramel", soldOut: false },
                { name: "Vanilla", soldOut: false },
                { name: "Gingerbread", soldOut: false },
                { name: "Cinnamon Roll", soldOut: false },
                { name: "Hazelnut", soldOut: false }
            ],
        },
        {
            type: "variable",
            name: "Cans",
            image: cansImage,
            masterPrice: 1.3,
            variants: [
                { name: "Coca-Cola", soldOut: false },
                { name: "Diet Coke", soldOut: false },
                { name: "7up", soldOut: false },
                { name: "Dr Pepper", soldOut: false },
                { name: "Fanta", soldOut: false },
                { name: "Bottled Water £1.00", soldOut: false },
            ],
        },
    ];


    const icecream = [
        { type: "normal", name: "Cinnamon Toffee Apple", description: "Apple pieces with cinnamon biscuit and a rich toffee sauce", price: 3.5, image: iceCreamImage, soldOut: false },
        { type: "normal", name: "Sugar plum fairy", description: "Strawberry pieces with white chocolate curls, shortbread biscuit with a delicious strawberry sauce and pink glitter dust", price: 3.5, image: iceCreamImage, soldOut: false },
        { type: "normal", name: "Grinch Delight", description: "Crushed Mint Aero chocolate, fluffy mini marshmallows, apple sherbet, and a rich strawberry sauce", price: 3.5, image: iceCreamImage, soldOut: false },
        { type: "normal", name: "Ginger Jingle Crunch", description: "Gingernut biscuit, smarties and smooth caramel sauce", price: 3.5, image: iceCreamImage, soldOut: false },
        { type: "normal", name: "Holly Jolly Pie", description: "Crumbled mince pie with a golden caramel sauce", price: 3.5, image: iceCreamImage, soldOut: false },
    ];


    const sweetTreats = [
        {
            type: "variable",
            name: "Candyfloss",
            image: candyflossImage,
            description: "In a bag",
            masterPrice: 2.5,
            variants: [
                { name: "Pink Vanilla", soldOut: false },
                { name: "Blue Raspberry", soldOut: false },
                { name: "Lemon", soldOut: false },
            ],
        },
        { type: "normal", name: "Large Twist Lollipop", price: 2.0, image: largeTwistLollipopImage, soldOut: false },
        { type: "normal", name: "Round Wheel Lollipop", price: 2.0, image: roundWheelLollipopImage, soldOut: false },
        { type: "normal", name: "Sugar Dummies", price: 1.8, image: sugarDummyImage, soldOut: false },
    ];

    const snacks = [
        {
            type: "variable",
            name: "Crisps",
            image: crispsImage,
            masterPrice: 1.2,
            variants: [
                { name: "Ready Salted", soldOut: false },
                { name: "Salt & Vinegar", soldOut: false },
                { name: "Cheese & Onion", soldOut: false },
                { name: "Quavers", soldOut: false },
                { name: "Wotsits", soldOut: false }
            ],
        },
        { type: "normal", name: "Popcorn", description: "Freshly made cinema style sweet popcorn", price: 2.0, image: popcornImage, soldOut: false }
    ];


    const toys = [
        { type: "normal", name: "Mini Disco Ball", price: 3.5, image: miniDiscoBall, soldOut: false },
        { type: "normal", name: "Flashing Foam Stick", price: 3.5, image: flashingFoamStick, soldOut: false },
        { type: "normal", name: "Smiley Face Fruity Scented Ball", price: 2.5, image: smileyFaceBallImage, soldOut: false }
    ];


    return (
        <div className="display-container">
            <Snowfall
                images={snowflakeImages}
                radius={[60,120]}
                snowflakeCount={40}
            />
            {/* Top Banner */}
            <div className="top-banner">
                <img src={Logo} alt="Blue Duo Catering Logo" className="logo" />
            </div>

            {/* Main Content */}
            <div className="main-content">
                <h1 className="main-title">Welcome to Blue Duo Catering</h1>

                <div className="category-container">
                    <Category title="Hot Drinks" products={drinks} />
                    <Category title="Ice-Cream" description="Plain ice-cream with any sauce also available - £3.00" products={icecream} />
                    
                    <Category title="Snacks" products={snacks} />
                    <Category title="Sweet Treats" products={sweetTreats} />
                    <Category title="Novelty Toys" products={toys} />
                    <img className='bundle-image' src={bundleImage} />
                    {/* Add more categories as needed */}
                </div>

                {/* Add more content like pictures or prices here */}
            </div>

            {/* Bottom Banner */}
            <div className="bottom-banner">
                <div className="contact-details">
                    <p>Email: contact@blueduo.events</p>
                    <p>Phone: 01352 372235</p>
                    <p>Website: www.blueduo.events</p>
                </div>
                <div className="food-hygine-rating">
                    <img src={FOOD_HYGINE_RATING} alt="5 Star Food Hygine Rating" />
                </div>
                <div className="ncass-logo">
                    <img src={NCASS_LOGO} alt="NCASS Logo" />
                </div>
                <div className="qr-code">
                    <img src={QRCode} alt="Scan for allergen details" />
                    <p>Scan for allergen details</p>
                </div>
            </div>
        </div>
    );
};

export default Display;
